import {
  DELIVERY_INFO_STATUS,
  PRODUCTION_RECORD_STATUS,
  TREATMENT_PLAN_VERSION_STATUS
} from './options';

const MOBILE_SCREEN_WIDTH = 768;
const TAB_SCREEN_WIDTH = 1200;
export enum APP_LANGUAGES {
  EN = 'en'
}

export const SMARTEE_HTTP_URL = 'http://check.zenyumsmileplans.com/';
export const KEEP_PLAN_OPENED = 'keepOpened';

export enum TPV_PAGE_CLICK_SOURCE {
  EMAIL = 'EMAIL',
  WHATSAPP = 'WHATSAPP',
  PATIENT_CARD = 'PATIENT_CARD',
  TPV_SWAPPER = 'TPV_SWAPPER'
}

export enum PATIENT_PAGE_SOURCE {
  QR_CODE = 'qrcode'
}

export enum DASHBOARD_TASKS_TYPE {
  RETAINER = 'RETAINER',
  ALIGNER = 'ALIGNER'
}

export const NO_EMAIL = 'noemail.com';

const CLINIC_CLOUD = 'Clinic Cloud';
const ZENBASE = 'ZenBase';

const REFERRAL_STATUS_VALID = 'VALID';
const REFERRAL_STATUS_WARNING = 'WARNING';
const REFERRAL_STATUS_DUPLICATE = 'DUPLICATE';
const REFERRAL_STATUS_INVALID = 'INVALID';

const CHAT_WITH_ZENYUM = 'CHAT_WITH_ZENYUM';
const REFINEMENT = 'refinement';
const ZENSTAGES = 'zenstages';
const TRIMMING = 'trimming';
const ILL_FITTING_ALIGNERS = 'ill-fitting_aligners';
export const MULTI_TAB_SYNC_STORAGE_KEY = 'syncTabs';

export {
  CHAT_WITH_ZENYUM,
  CLINIC_CLOUD,
  ZENBASE,
  REFINEMENT,
  ILL_FITTING_ALIGNERS,
  ZENSTAGES,
  TRIMMING
};
const USER_ROLES = {
  DOCTOR: 'DOCTOR',
  NURSE: 'NURSE',
  SUPER_ADMIN: 'SUPER_ADMIN',
  CLINIC_ADMIN: 'CLINIC_ADMIN',
  AFTERSALES_DOCTOR: 'AFTERSALES_DOCTOR',
  CS_ADMIN: 'CS_ADMIN',
  CC_ADMIN: 'CC_ADMIN',
  SALES_OPERATIONS: 'SALES_OPERATIONS',
  OTHERS: 'OTHERS',
  EXTERNAL_DOCTOR: 'EXTERNAL_DOCTOR',
  TREATMENT_PLANNING_DOCTOR: 'TREATMENT_PLANNING_DOCTOR',
  TREATMENT_PLANNING_LEAD: 'TREATMENT_PLANNING_LEAD',
  CUSTOMER_CARE: 'CUSTOMER_CARE',
  OPERATIONS_DENTISTRY: 'OPERATIONS_DENTISTRY',
  PARTNERSHIP_MANAGER: 'PARTNERSHIP_MANAGER',
  PRE_ASSESSMENT_LEAD: 'PRE_ASSESSMENT_LEAD',
  PRE_ASSESSMENT_DOCTOR: 'PRE_ASSESSMENT_DOCTOR',
  PATIENT: 'PATIENT',
  CASE_OPERATIONS: 'CASE_OPERATIONS',
  CASE_OPERATIONS_ADMIN: 'CASE_OPS_ADMIN',
  ADMIN: 'ADMIN',
  CUSTOMER_SUCCESS: 'CUSTOMER_SUCCESS',
  CHAT_OWNER: 'CHAT_OWNER',
  INTERNAL_DOCTOR: 'INTERNAL_DOCTOR',
  CLINICAL_TRAINER: 'CLINICAL_TRAINER',
  CLINICAL_TRAINER_HQ: 'CLINICAL_TRAINER_HQ',
  PATIENT_CARE_LEAD: 'PATIENT_CARE_LEAD',
  ZENYUM_COUNCIL_ADMIN: 'ZENYUM_COUNCIL_ADMIN'
};
export const EXTERNAL_ROLES = [
  USER_ROLES.NURSE,
  USER_ROLES.EXTERNAL_DOCTOR,
  USER_ROLES.CLINIC_ADMIN,
  USER_ROLES.OTHERS
];
export const INTERNAL_ROLES = [
  USER_ROLES.CUSTOMER_CARE,
  USER_ROLES.CASE_OPERATIONS,
  USER_ROLES.CUSTOMER_SUCCESS,
  USER_ROLES.PRE_ASSESSMENT_LEAD,
  USER_ROLES.AFTERSALES_DOCTOR,
  USER_ROLES.SALES_OPERATIONS,
  USER_ROLES.PARTNERSHIP_MANAGER,
  USER_ROLES.PRE_ASSESSMENT_DOCTOR,
  USER_ROLES.TREATMENT_PLANNING_LEAD,
  USER_ROLES.TREATMENT_PLANNING_DOCTOR,
  USER_ROLES.OPERATIONS_DENTISTRY,
  USER_ROLES.CLINICAL_TRAINER,
  USER_ROLES.CLINICAL_TRAINER_HQ,
  USER_ROLES.CASE_OPERATIONS_ADMIN,
  USER_ROLES.CC_ADMIN,
  USER_ROLES.CS_ADMIN,
  USER_ROLES.PATIENT_CARE_LEAD,
  USER_ROLES.SUPER_ADMIN,
  USER_ROLES.ZENYUM_COUNCIL_ADMIN
];

// temp work around for translation
const TREATMENT_STATUS = {
  COMPLETE: 'status.treatment_complete',
  PENDING_OFF_BOARDING: 'status.pending_patient_off_boarding_approval',
  PENDING_DOCTOR_REVIEW: 'status.pending_doctor_review',
  PENDING_RETAINER_SCANS: 'status.pending_retainer_scans_upload',
  PENDING_EOT_DISCUSSION: 'status.pending_EOT_discussion',
  PENDING_ZENYUM_COUNCIL_ASSESSMENT: 'status.pending_zenyum_council_assessment'
};

export const STAGES = {
  PENDING_PRESCRIPTION: 'PENDING_PRESCRIPTION',
  TREATMENT_PLAN_REVIEW: 'TREATMENT_PLAN_REVIEW',
  ALL_ALIGNERS: 'ALL_ALIGNERS'
};

export const stages = {
  PENDING_PRESCRIPTION: 'Pending prescription'
};

export const TREATMENT_STATUSES = {
  PENDING_CONSULTATION_APPOINTMENT: 'status.pending_consultation_appointment',
  CONSULTATION_APPOINTMENT_OVERDUE: 'status.consultation_appointment_overdue',
  PENDING_DOCTOR_ASSESSMENT: 'status.pending_doctor_assessment',
  DISCUSSION_REQUIRED: 'status.pending_discussion',
  PATIENT_ACCEPTED_PENDING_FITTING_APPOINTMENT:
    'status.patient_accepted__pending_fitting_appointment',
  FITTING_APPOINTMENT_BOOKED: 'status.fitting_appointment_booked',
  PENDING_CONSENT_FORM: 'status.pending_consent_upload',
  CREATION_IN_PROGRESS: 'status.creation_in_progress'
};

export enum TREATMENT_STATUS_VALUES {
  PENDING_DOCTOR_ASSESSMENT = 'PENDING_DOCTOR_ASSESSMENT',
  TREATMENT_COMPLETE = 'TREATMENT_COMPLETE',
  PENDING_RETAINER_SCANS_UPLOAD = 'PENDING_RETAINER_SCANS_UPLOAD'
}

export const RETAINER_TREATMENT_STATUS = {
  PENDING_PRESCRIPTION: 'status.pending_prescription',
  RESUBMIT_SCAN: 'status.resubmit_scan',
  WAITING_ALIGNER_TREATMENT: 'status.waiting_for_aligner_treatment'
};

export {
  REFERRAL_STATUS_VALID,
  REFERRAL_STATUS_WARNING,
  REFERRAL_STATUS_DUPLICATE,
  USER_ROLES,
  MOBILE_SCREEN_WIDTH,
  TAB_SCREEN_WIDTH,
  TREATMENT_STATUS,
  REFERRAL_STATUS_INVALID
};
export const DAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
];

export const SCANNER_TYPE_VALUES = [
  'INTRAORAL_SCANNER_3SHAPE',
  'INTRAORAL_SCANNER_ITERO',
  'INTRAORAL_SCANNER_OTHERS'
];

export const EXCLUDE_ROLES = ['CHAT_OWNER', 'ADMIN', 'AB_CHALLENGER'];

export const ROLES: Record<string, string> = {
  EXTERNAL_DOCTOR: 'Doctor',
  NURSE: 'Nurse',
  EMPLOYEE: 'Employee',
  CUSTOMER_CARE: 'Customer Care',
  CASE_OPERATIONS: 'Case Operations',
  CUSTOMER_SUCCESS: 'Customer Success',
  AFTERSALES_DOCTOR: 'Aftersales Doctor',
  PRE_ASSESSMENT_LEAD: 'Pre-assessment lead',
  PRE_ASSESSMENT_DOCTOR: 'Pre-assessment Doctor',
  PARTNERSHIP_MANAGER: 'Partnership Manager',
  OPERATIONS_DENTISTRY: 'Operations Dentistry',
  TREATMENT_PLANNING_LEAD: 'Treatment Planning Lead',
  TREATMENT_PLANNING_DOCTOR: 'Treatment Planning Doctor',
  CC_ADMIN: 'Cc Admin',
  CLINICAL_TRAINER: 'Clinical Trainer',
  CLINICAL_TRAINER_HQ: 'Clinical Trainer HQ',
  CLINIC_ADMIN: 'Clinic Admin',
  CS_ADMIN: 'CS Admin',
  OTHERS: 'Others',
  ZENYUM_COUNCIL_ADMIN: 'Zenyum Council Admin',
  PATIENT_CARE_LEAD: 'Patient Care Lead',
  CASE_OPS_ADMIN: 'Case Operations Admin'
};

export const DEFAULT_REFERRAL_BONUS = 'S$650';

export const BothTreatmentPlans = 'both';
export const ZenPlus = 'ZENCLEAR_PLUS';
export const ZenClear = 'ZENCLEAR';

export const STATUS_CODE = {
  BOOKED: 'BOOKED'
};
export const NONEVALUE = 'None';
export const EXISTING_PHOTO = 'existingPhoto';
export enum COUNTRY_CODE {
  SINGAPORE = 'SG',
  INDONESIA = 'ID',
  MACAU = 'MO'
}
export const WIDTH_768 = 768;
export const ALLOWED_PHOTO_MIME_TYPE =
  'image/jpeg, image/png, image/jpg, image/jp2';

export const ALLOWED_FILE_MIME_TYPE =
  'image/jpeg, image/png, image/jpg, application/pdf';

export enum YES_NO {
  YES = 'YES',
  NO = 'NO'
}

export const YES_NO_MAPPER: Record<string, boolean> = {
  [YES_NO.YES]: true,
  [YES_NO.NO]: false
};

export enum EXTENSIONS {
  STL = '.stl'
}

export const ACCEPTANCE_STATUS = {
  REJECTED: 'REJECTED'
};

export const EXTERNAL_DOCTOR = 'TREATING_DOCTOR';
export const INTERNAL_DOCTOR = 'INTERNAL_DOCTOR';
export const PATIENT = 'PATIENT';

export const ROLES_TO_EXCLUDE = ['CHAT_OWNER'];

export const EMAIL_REGEX =
  /^\s*(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/;

export const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'obj', 'stl', 'pdf'];

export const ALLOWED_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'jp2'];

export enum TREATMENT_PLAN_TYPES {
  ZENCLEAR = 'ZenyumClear',
  ZENCLEAR_PLUS = 'ZenyumClear Plus'
}

export enum TREATMENT_PLANNING_LAB {
  SMARTEE = 'Smartee',
  FULLCONTOUR = 'FullContour'
}

export const KEYBOARD_KEYS = {
  ENTER: 'Enter',
  BACKSPACE: 'Backspace'
};
export const CLINIC_SWAP_CONSTANTS = {
  EMAIL: 'EMAIL',
  PATH: '/tpv/'
};

export const IN_PLANNING_STATUSES = [
  TREATMENT_PLAN_VERSION_STATUS.IN_PLANNING,
  TREATMENT_PLAN_VERSION_STATUS.CASE_IN_PLANNING,
  TREATMENT_PLAN_VERSION_STATUS.CASE_WITH_ZENYUM,
  TREATMENT_PLAN_VERSION_STATUS.CASE_OPS_TPV_CHECK,
  TREATMENT_PLAN_VERSION_STATUS.CASE_WITH_INT_DOC
];

export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC'
}

export const TREATMENT_PLANNING_TEMPLATE = 'TREATMENT_PLANNING_TEMPLATE';
export const CLINICAL_PREFERENCE = 'CLINICAL_PREFERENCE';
export const TEMPLATIZED_PRESCRIPTION = 'TEMPLATIZED_PRESCRIPTION';
export const FEATURE_WALKTHROUGH = 'FEATURE_WALKTHROUGH';
export const AUTO_INTERNAL_TPV_APPROVAL = 'AUTO_INTERNAL_TPV_APPROVAL';
export const ZENCHAT_VISIBILITY = 'ZENCHAT_VISIBILITY';
export const ACCEPTED_IMAGE_FORMATS = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/jp2'
];
export const LIVE_TRANSLATION_HIDDEN_COUNTRIES = [
  'SG',
  'MY',
  'TW',
  'ID',
  'HK',
  'MO'
];
export const SKIP_INT_DOC_REJECTION_REASONS = {
  PLAN_TO_BE_RESIMULATED: 'PLAN_TO_BE_RESIMULATED',
  PREV_PLAN_MORE_SUITABLE: 'PREV_PLAN_MORE_SUITABLE',
  PATIENT_NEED_TP_UPGRADE: 'PATIENT_NEED_TP_UPGRADE',
  CANNOT_TREAT_PATIENT: 'CANNOT_TREAT_PATIENT'
};
export const LIVE_TRANSLATION_VISIBLE_REJECT_REASONS = [
  SKIP_INT_DOC_REJECTION_REASONS.CANNOT_TREAT_PATIENT,
  SKIP_INT_DOC_REJECTION_REASONS.PATIENT_NEED_TP_UPGRADE,
  SKIP_INT_DOC_REJECTION_REASONS.PLAN_TO_BE_RESIMULATED
];

export const IN_PRODUCTION_STATUSES = [
  PRODUCTION_RECORD_STATUS.IN_PRODUCTION,
  PRODUCTION_RECORD_STATUS.TRIMMING,
  PRODUCTION_RECORD_STATUS.READY_FOR_SHIPMENT,
  PRODUCTION_RECORD_STATUS.SHIPPED
];
export const DELIVERY_INFO_MESSAGES: Record<string, string> = {
  Production: 'Production.Info.Message',
  Warehouse: 'Warehouse.Info.Message',
  Dispatched: 'Dispatched.Info.Message',
  Received: 'Received.Info.Message'
};
export const DELIVERY_INFO_STATUSES = [
  DELIVERY_INFO_STATUS.PRODUCTION,
  DELIVERY_INFO_STATUS.WAREHOUSE,
  DELIVERY_INFO_STATUS.DISPATCHED,
  DELIVERY_INFO_STATUS.RECEIVED
];
export const DELIVERY_INFO_STATUSES_LABEL: Record<string, string> = {
  Production: 'deliveryInfo.Production',
  Warehouse: 'deliveryInfo.Warehouse',
  Dispatched: 'deliveryInfo.Dispatched',
  Received: 'deliveryInfo.Received'
};
export const PRODUCTION_STATUSES_IGNORED = [
  'UPLOAD_DESIGN_FILES',
  'TO_BE_REVIEWED',
  'WAITING_FOR_PRODUCTION',
  'TO_BE_PRODUCED'
];

export const TREATMENT_PLAN_STATUS = {
  PRESCRIPTION_PENDING: 'PRESCRIPTION_PENDING'
};
